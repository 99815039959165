import React from "react";
import {graphql} from "gatsby";
import {getParagraph} from "../helpers/paragraph-helpers";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Title from "../components/Title";
import Form from "../components/Form";
import MenuLink from "../components/MenuLink";
import CustomBreadcrumb from "../components/CustomBreadcrumb";

const Page = ({pageContext, data}) => {
    const {
        breadcrumb: {crumbs},
    } = pageContext

    const paragraphs = data.node.relationships.paragraphs.map(getParagraph);

    return (
        <Layout contentType={data.node.internal.type}>
            <Metas title={data.node.title}/>
            <section className="section page-breadcrumb is-small has-background-light">
                <div className="container">
                    <CustomBreadcrumb crumbs={crumbs}/>

                    {data.children && data.children.nodes.length > 0 && (
                        <div className="columns is-multiline">
                            {data.children.nodes.map((item, index) => (
                                <div key={index} className="column is-4 is-3-desktop portal-link-small">
                                    <MenuLink internalId={item.link.uri} {...item.link.options.attributes}>
                                        {item.title}
                                        <span className="icon icon-medium">
                                            <i className="li li-plus"></i>
                                        </span>
                                    </MenuLink>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </section>
            <section className="section page-content">
                <div className="container">
                    <Title title={data.node.title}/>
                    <div className="paragraphs-list">
                        {paragraphs}
                    </div>
                </div>
            </section>
            {data.node.relationships.webform && (
                <section className="section page-webform has-background-light">
                    <div className="container">
                        <Form webform={data.node.relationships.webform}/>
                    </div>
                </section>
            )}
        </Layout>
    )
}

export default Page

export const query = graphql`
    query ($internalId: Int!, $menuItemId: String) {
        node: nodePage(
            status: {eq: true},
            drupal_internal__nid: {eq: $internalId}
        ) {
            title
            body {
                value
            }
            changed
            relationships {
              paragraphs: field_paragraphes {
                type: __typename
                ... on Node {
                  ...TextParagraph
                  ...BannerParagraph
                  ...HighlightedParagraph
                  ...ListParagraph
                  ...FilesParagraph
                  ...ImageGalleryParagraph
                }
              }
              webform: field_webform {
                title
                drupal_internal__id
                elements {
                  name
                  type
                  attributes {
                    name
                    value
                  }
                }
              }
            }
            internal {
                type
            }
        }
        children: allMenuLinkContentMenuLinkContent(
            filter: {
                enabled: {eq: true},
                menu_name: {eq: "main"},
                drupal_parent_menu_item: {
                    eq: $menuItemId,
                    ne: null
                }
            }
            sort: {fields: weight, order: ASC}
        ) {
            nodes {
                title
                drupal_parent_menu_item
                drupal_id
                link {
                    uri
                    options {
                        attributes {
                            data_has_icon
                            data_icon
                            data_text_invisible
                            target
                        }
                    }
                }
            }
        }
    }
`
